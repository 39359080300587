//Valida el formulario de Cambio de clave
//  devuelve un objeto con el resultado de la validación

import validator from 'validator';

const ValidaClave = (usuario, formu) => {

    const expresiones = {
        password: /^.{6,16}$/                     // 6 a 16 digitos.
    }

    //console.log(usuario);
    //console.log(formu);

    //Validamos el campo CLAVEACTUAL
    if (usuario.clave !== formu.claveactual) {
        return {
            campo: "Clave actual",
            error: "no es correcta"
        };
    }
    if (validator.isEmpty(formu.claveactual)) {
        return {
            campo: "Clave actual",
            error: "no puede estar vacío"
        };
    }
    if (!validator.isLength(formu.claveactual, {min: 6, max: 16})) {
        return {
            campo: "Clave actual",
            error: "Debe tener entre 6 y 16 caracteres"
        };
    }

    if (!expresiones.password.test(formu.claveactual)) {
        return {
            campo: "Clave actual",
            error: "Permitido cualquier caracter entre 6 y 16 posiciones"
        };
    }

    //Validamos el campo CLAVENEW1
    if (validator.isEmpty(formu.clavenew1)) {
        return {
            campo: "Clave nueva",
            error: "no puede estar vacío"
        };
    }
    if (!validator.isLength(formu.clavenew1, {min: 6, max: 16})) {
        return {
            campo: "Clave nueva",
            error: "Debe tener entre 6 y 16 caracteres"
        };
    }

    if (!expresiones.password.test(formu.clavenew1)) {
        return {
            campo: "Clave nueva",
            error: "Permitido cualquier caracter entre 6 y 16 posiciones"
        };
    }

    //Validamos el campo CLAVENEW2
    if (validator.isEmpty(formu.clavenew2)) {
        return {
            campo: "Clave repetida",
            error: "no puede estar vacío"
        };
    }
    if (!validator.isLength(formu.clavenew2, {min: 6, max: 16})) {
        return {
            campo: "Clave repetida",
            error: "Debe tener entre 6 y 16 caracteres"
        };
    }

    if (!expresiones.password.test(formu.clavenew2)) {
        return {
            campo: "Clave repetida",
            error: "Permitido cualquier caracter entre 6 y 16 posiciones"
        };
    }

    if (formu.clavenew1 !== formu.clavenew2) {
        return {
            campo: "Las nuevas claves",
            error: "deben de ser iguales"
        };
    }

    // Si no ha salido por ningún sitio es que todo está correcto
    return {
        campo: "OK",
        error: "Todo correcto"
    };
}

export default ValidaClave;