import React, { useRef } from 'react';

const Desconectar = ({usuario, setAccion}) => {

    const refCancelar = useRef();

    const desconectar = () => {
        console.log("He pulsado Aceptar desconexión");
        setAccion("Desconectar");
        refCancelar.current.click();
    }

    return (
        <>
        <div className="col-auto iconp" data-bs-toggle="tooltip" data-bs-placemente="top" title="Desconectar">
            <i className="bi bi-power icono" data-bs-toggle="modal" data-bs-target="#ModalDesconectar" ></i>
        </div>

        {/* Ventana ModalDesconectar */}
        <div className="modal fade" id="ModalDesconectar" tabIndex="-1" aria-labelledby="TituloModal" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="TituloModal">Confirmación</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>Si desconecta se olvidarán los datos de usuario y deberá de loguearse de nuevo cuando quiera entrar. ¿Quiere desconectar?</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" ref={refCancelar}>Cancelar</button>
                        <button type="button" className="btn btn-primary" onClick={desconectar}>Aceptar</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Desconectar;