
import './Apunte.css';
import React from 'react';
import { useState, useEffect, useRef, useContext } from 'react';
import { useForm } from '../hooks/useForm';
import toast, {Toaster} from 'react-hot-toast';
import LlamaAPI from '../helpers/LlamaAPI';
import ValidaApunte from '../helpers/ValidaApunte';
import Cabecera from './Cabecera';
import ModoEjec from '../contextos/ModoEjec';
const moment = require('moment');

const Apunte = ({usuario, setMostrar, setUsuario}) => {
    const a = moment();
    const fechaAct = a.format('YYYY-MM-DD');
    const [formuCompleto, setFormuCompleto] = useState(false);     //lo cambia useForm cuando el formulario está completo
    const [respuestaAPI, setRespuestaAPI] = useState({paso: "ninguno"});
    const [accion, setAccion] = useState("Nada");                  //Lo cambia la cabecera para saber qué ha pulsado el usuario
    const [conceptos, setConceptos] = useState([{}]);              //Almacena la lista de conceptos
    const {formu, enviado, cambiado} = useForm({fecha: fechaAct,
                                                concepto: 0}, setFormuCompleto);

    const refFecha = useRef();
    const refConcepto = useRef();
    const refComentario = useRef();
    const refImporte = useRef();
    //const refAlerta = useRef();
    //const refInfoAlerta = useRef();

    const { modoejecucion } = useContext(ModoEjec);

    //Se ejecuta al inicio del componente
    useEffect( () => {
        console.log(usuario);
        let url;
        if (modoejecucion === "produccion") {
            url = "https://eco.minave.es/api/conceptos/lista/" + usuario.coduser;
        } else {
            url = "http://localhost:3003/api/conceptos/lista/" + usuario.coduser;
        }
        LlamaAPI('listaconcep', url, 'GET', null, setRespuestaAPI);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Esto se ejecuta cuando se pulsa Aceptar en el formulario
    useEffect( () => {
        if (formuCompleto) {
            console.log("Se ha enviado el formulario");
            console.log(formu);

            //Validar los campos del formulario
            const objResVal = ValidaApunte(formu);   //Devuelve un objeto con el resultado de la validación
            if (objResVal.campo !== "OK") {
                console.log("Tenemos error en el campo: " + objResVal.campo + " Descripción: " + objResVal.error);
                //Mostrar el error en un toast
                toast.error("El campo " + objResVal.campo + " " + objResVal.error, {
                    style: {
                        border: '1px solid #713200',
                        background: '#fabcb9',  //Rojo suave
                        color: '#000000',
                    }
                });
                //refInfoAlerta.current.innerHTML = "El campo " + objResVal.campo + " " + objResVal.error;
                //refAlerta.current.classList.remove('alert-success');
                //refAlerta.current.classList.add('alert-danger');
                //refAlerta.current.classList.remove('oculta');
                switch (objResVal.campo) {
                    case "Concepto":
                        //refConcepto.current.select();
                        break;
                    case "Comentario":
                        refComentario.current.select();
                        break;
                    case "Importe":
                        refImporte.current.select();
                        break;
                    default:
                        break;
                }
            } else {
                //Validación correcta conectar con API para registrar el apunte
                console.log(objResVal.error);
                let obj = formu;
                obj.fecha = obj.fecha.replaceAll("-","");    //Elimina todos los guiones que tenga fecha
                obj.coduser = usuario.coduser;                //Añadimos al objeto los campos de código de usuario y alias
                obj.alias = usuario.alias;
                let url;
                if (modoejecucion === "produccion") {
                    url = "https://eco.minave.es/api/apuntes/alta/";
                } else {
                    url = "http://localhost:3003/api/apuntes/alta/";
                }
                LlamaAPI('salvarapunte', url, 'POST', obj, setRespuestaAPI);
            }
            
            //Cambiamos a false para que se pueda pulsar otra vez enviar y reprocesar el formulario
            setFormuCompleto(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formuCompleto]);

    // Trata las posibles respuestas de las APIS
    useEffect ( () => {

        if (respuestaAPI.paso === 'listaconcep') {
            if (respuestaAPI.respuesta.status === "correcto") {
                setConceptos(respuestaAPI.respuesta.conceptos);     //Actualizamos el estado con los conceptos recibidos
            }
            if (respuestaAPI.respuesta.status === "sin datos") {
                console.log("Error al obtener la lista: " + respuestaAPI.respuesta.descripcion);
                toast.error("Error al obtener conceptos: " + respuestaAPI.respuesta.descripcion, {
                    style: {
                        border: '1px solid #713200',
                        background: '#0146a5',
                        color: '#000000',
                    }
                });
                //refInfoAlerta.current.innerHTML = "Error al obtener conceptos: " + respuestaAPI.respuesta.descripcion;
                //refAlerta.current.classList.remove('alert-success');
                //refAlerta.current.classList.add('alert-danger');
                //refAlerta.current.classList.remove('oculta');
            }
            if (respuestaAPI.respuesta.status === "errorsys") {
                console.log("Error al obtener conceptos: " + respuestaAPI.respuesta.error);
                toast.error("Error al obtener conceptos: " + respuestaAPI.respuesta.descripcion, {
                    style: {
                        border: '1px solid #713200',
                        background: '#0146a5',
                        color: '#000000',
                    }
                });
                //refInfoAlerta.current.innerHTML = "Error al obtener conceptos: " + respuestaAPI.respuesta.error;
                //refAlerta.current.classList.remove('alert-success');
                //refAlerta.current.classList.add('alert-danger');
                //refAlerta.current.classList.remove('oculta');
            }
        }

        if (respuestaAPI.paso === 'salvarapunte') {
            if (respuestaAPI.respuesta.status === "correcto") {
                console.log("Apunte guardado satisfactoriamente con código: " + respuestaAPI.respuesta.codapunte);
                toast.success("Apunte guardado satisfactoriamente con código: " + respuestaAPI.respuesta.codapunte, {
                    style: {
                        border: '1px solid #713200',
                        background: '#b9fab9',
                        color: '#000000',
                    }
                });
                //refInfoAlerta.current.innerHTML = "Apunte guardado satisfactoriamente con código: " + respuestaAPI.respuesta.codapunte;
                //refAlerta.current.classList.remove('alert-danger');
                //refAlerta.current.classList.add('alert-success');
                //refAlerta.current.classList.remove('oculta');
                refFecha.current.value = fechaAct;
                refConcepto.current.value = "0";
                refComentario.current.value = "";
                refImporte.current.value = "";
            }
            if (respuestaAPI.respuesta.status === "errorsys") {
                console.log("Error al guardar apunte: " + respuestaAPI.respuesta.error);
                toast.error("Error al guardar apunte: " + respuestaAPI.respuesta.error, {
                    style: {
                        border: '1px solid #713200',
                        background: '#0146a5',
                        color: '#000000',
                    }
                });
                //refInfoAlerta.current.innerHTML = "Error al guardar apunte: " + respuestaAPI.respuesta.error;
                //refAlerta.current.classList.remove('alert-success');
                //refAlerta.current.classList.add('alert-danger');
                //refAlerta.current.classList.remove('oculta');
            }
        }

        if (respuestaAPI.paso === "logout") {
            if (respuestaAPI.respuesta.status === 'correcto') {
                console.log("Recibido Logout: " + respuestaAPI.respuesta.descripcion);
                localStorage.removeItem('datos');
                setMostrar("Login");
            }
            if (respuestaAPI.respuesta.status === 'errorprog') {
                toast.error("Error en la desconexión: " + respuestaAPI.respuesta.descripcion, {
                    style: {
                        border: '1px solid #713200',
                        background: '#0146a5',
                        color: '#000000',
                    }
                });
                //refInfoAlerta.current.innerHTML = "Error en la desconexión: " + respuestaAPI.respuesta.descripcion;
                //refAlerta.current.classList.remove('alert-success');
                //refAlerta.current.classList.add('alert-danger');
                //refAlerta.current.classList.remove('oculta');
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [respuestaAPI]);

    useEffect ( () => {
        if (accion === "CambiaClave") {
            console.log("He pulsado CambiarClave");
        }

        if (accion === "Desconectar") {
            console.log("Ha confirmado la desconexión");
            let url;
            if (modoejecucion === "produccion") {
                url = "https://eco.minave.es/api/usuarios/logout/";
            } else {
                url = "http://localhost:3003/api/usuarios/logout/";
            }
            LlamaAPI('logout', url, 'DELETE', usuario, setRespuestaAPI);
        }

        if (accion === "modal-cancelar") {
            console.log("He pulsado cancelar en la ventana modal");
        }

    // eslint-disable-next-line
    }, [accion]);

    const borrar = () => {
        // Limpiar los campos del formulario
        refFecha.current.value = fechaAct;
        refConcepto.current.value = "0";
        refComentario.current.value = "";
        refImporte.current.value = "";
    }

    return (
        <>
        <div className='container-fluid'>

            <Cabecera usuario={usuario} setUsuario={setUsuario} setAccion={setAccion} />
{/*
            <section id="cajaalerta" className="row alert alert-danger alert-dismissible fade show oculta" role="alert" ref={refAlerta}>
                <p>
                    <strong>Aviso: </strong>
                    <label id="texto" ref={refInfoAlerta}>
                    </label>
                </p>
            </section>
*/}
            <section id="cuerpo" className="row">
                <h3 className="col-12">Apunte remoto</h3>
                <form onSubmit={enviado} className="col-12">

                    <div className="mb-3">
                        <label htmlFor="fecha" className="form-label">Fecha del apunte</label>
                        <input type="date" className="form-control" id="fecha" name="fecha" onChange={cambiado} ref={refFecha} value={formu.fecha}/>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="concepto">Concepto</label>
                        <select className="form-select" name="concepto" onChange={cambiado} ref={refConcepto} aria-label="Default select example">
                            <option defaultValue value="0">Selecciona un concepto</option>
                            {conceptos.map( (el, ind) => {
                                return (<option value={el.Cod_Concepto} key={ind}>{el.Tipo + "-" + el.Concepto}</option>)
                            })}
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="comentario" className="form-label">Comentario</label>
                        <input type="text" className="form-control" id="comentario" name="comentario" onChange={cambiado} ref={refComentario} />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="importe" className="form-label">Importe</label>
                        <input type="text" className="form-control" id="importe" name="importe" onChange={cambiado} ref={refImporte} />
                    </div>


                    <div id="botones">
                        <button type="submit" className="btn btn-primary aceptar" name="aceptar">Aceptar</button>
                        <button type="button" className="btn btn-primary" name="Borrar" onClick={borrar}>Borrar</button>
                    </div>
                </form>
            </section>

            <footer id="footApunte">
                <p>Fecha={formu.fecha} Concepto={formu.concepto} Comentario={formu.comentario} Importe={formu.importe}</p> 
            </footer>
        </div>
        <Toaster position='top-center' />
        </>
    )
}

export default Apunte;