const LlamaAPI = async (paso, url, metodo, cuerpo, setRespuestaAPI) => {
    console.log("LlamaAPI: " + paso + " url: " + url + " cuerpo: " + cuerpo);
    let opciones = {};
    if (cuerpo === null) {
        opciones = {
            method: metodo,
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };
    } else {
        opciones = {
            method: metodo,
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(cuerpo)
        };
    }

    const peticion = await fetch(url, opciones);
    const datos = await peticion.json();

    setRespuestaAPI({...setRespuestaAPI,
                       paso,
                       respuesta: datos });
    return;
}

export default LlamaAPI;