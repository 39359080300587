import './Cabecera.css';
import React from 'react';
import CambiaClave from './CambiaClave';
import Desconectar from './Desconectar';

const Cabecera = ({usuario, setUsuario, setAccion}) => {

    return (
        <header id="cabecera" className="row">
            <h4 className="col-auto me-auto">Hola {usuario.alias}</h4>

            <div className="col-auto">
                <CambiaClave usuario={usuario} setUsuario={setUsuario}/>
            </div>

            <div className="col-auto">
                <Desconectar setAccion={setAccion} />
            </div>

        </header>

    )
}

export default Cabecera;