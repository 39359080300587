import React, { useRef, useState, useEffect, useContext } from "react";
import { useForm } from '../hooks/useForm';
import validaClave from '../helpers/ValidaClave';
import LlamaAPI from '../helpers/LlamaAPI';
import ModoEjec from '../contextos/ModoEjec';

const CambiaClave = ({usuario, setUsuario}) => {

    const refCancelar = useRef();
    const refClaveActual = useRef();
    const refClaveNew1 = useRef();
    const refClaveNew2 = useRef();
    const refMenCambiaClave = useRef();

    const [formuCompleto, setFormuCompleto] = useState(false);
    const {formu, enviado, cambiado} = useForm({}, setFormuCompleto);
    const [respuestaAPI, setRespuestaAPI] = useState({paso: "ninguno"});

    const { modoejecucion } = useContext(ModoEjec);

    useEffect ( () => {
        refClaveActual.current.value = "";
        refClaveNew1.current.value = "";
        refClaveNew2.current.value = "";
    }, []);

    useEffect ( () => {
        if (respuestaAPI.paso === "cambiaclave") {
            if (respuestaAPI.respuesta.status === 'correcto') {
                refMenCambiaClave.current.innerHTML = respuestaAPI.respuesta.descripcion;
                refMenCambiaClave.current.style.color = "#03872c";
                refClaveActual.current.value = "";
                refClaveNew1.current.value = "";
                refClaveNew2.current.value = "";
                //Cambiar la clave en el objeto usuario y si están los datos guardados en LocalStorage también
                let objeto = usuario;
                objeto.clave = formu.clavenew1;
                setUsuario(objeto);
                if (objeto.guarda === "on") {
                    localStorage.setItem('datos', JSON.stringify(objeto));
                }
            }
            if (respuestaAPI.respuesta.status === 'errorprog') {
                refMenCambiaClave.current.innerHTML = respuestaAPI.respuesta.descripcion;
                refMenCambiaClave.current.style.color = "#8f0601";
            }
            if (respuestaAPI.respuesta.status === 'errorsys') {
                refMenCambiaClave.current.innerHTML = "Error: " + respuestaAPI.respuesta.error;
                refMenCambiaClave.current.style.color = "#8f0601";
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [respuestaAPI]);

    const aceptar = () => {
        //Realizar validación de datos
        const objValidacion = validaClave(usuario, formu); 
        if (objValidacion.campo === "OK") {
            console.log("Validación cambio clave correcta");
            let url;
            if (modoejecucion === "produccion") {
                url = "https://eco.minave.es/api/usuarios/cambiaclave/" + usuario.coduser;
            } else {
                url = "http://localhost:3003/api/usuarios/cambiaclave/" + usuario.coduser;
            }
            LlamaAPI('cambiaclave', url, 'PUT', {clavenew: formu.clavenew1}, setRespuestaAPI);
        } else {
            console.log("Error en Validación. Campo: " + objValidacion.campo + " " + objValidacion.error);
            refMenCambiaClave.current.innerHTML = objValidacion.campo + " " + objValidacion.error;
            refMenCambiaClave.current.style.color = "#8f0601";
        }
    }

    const borrar = () => {
        refClaveActual.current.value = "";
        refClaveNew1.current.value = "";
        refClaveNew2.current.value = "";
        refMenCambiaClave.current.innerHTML = "";
        refClaveActual.current.select();
    }

    return (
        <>
        <div className="col-auto" data-bs-toggle="tooltip" data-bs-placemente="top" title="Mi perfil">
            <i className="bi bi-person-fill icono" data-bs-toggle="modal" data-bs-target="#ModalCambiaClave" ></i>    {/* onClick={cambiaPwd} */}
        </div>

        {/* Ventana ModalCambiaClave */}
        <div className="modal fade" id="ModalCambiaClave" tabIndex="-1" aria-labelledby="TituloModal" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="TituloModal">Cambiar clave</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <section className='row justify-content-center'>
                            <div className='col10'>
                                <form className="col-12">
                                    <div className="mb-3">
                                        <label htmlFor="claveini" className="form-label">Clave actual</label>
                                        <input type="password" className="form-control" id="claveactual" name="claveactual" onChange={cambiado} ref={refClaveActual} />

                                        <label htmlFor="clavenew1" className="form-label">Clave nueva</label>
                                        <input type="password" className="form-control" id="clavenew1" name="clavenew1" onChange={cambiado} ref={refClaveNew1} />

                                        <label htmlFor="clavenew2" className="form-label">Repite clave nueva</label>
                                        <input type="password" className="form-control" id="clavenew2" name="clavenew2" onChange={cambiado} ref={refClaveNew2} />
                                    </div>
                                </form>
                            </div>
                            <div className='col-10' id="mensajeCambiaClave">
                                <p ref={refMenCambiaClave}></p>
                            </div>
                        </section>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" ref={refCancelar}>Cancelar</button>
                        <button type="button" className="btn btn-secondary" onClick={borrar}>Borrar</button>
                        <button type="button" className="btn btn-primary" onClick={aceptar}>Aceptar</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default CambiaClave;