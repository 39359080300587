//Valida el formulario de Apunte
//  devuelve un objeto con el resultado de la validación

import validator from 'validator';

const ValidaApunte = (formu) => {

    const expresiones = {
        usuario: /^[a-zA-Z0-9\_\-]{4,10}$/,        //eslint-disable-line
        password: /^.{6,16}$/,                     // 6 a 16 digitos.
        comentario: /^[a-zA-Z0-9À-ÿ\s]{5,30}$/,            // Letras y espacios, pueden llevar acentos.
    }

    //Validamos el campo FECHA
    if (formu.fecha === undefined) {
        return {
            campo: "Fecha",
            error: "selecciona una fecha"
        };
    }

    //Validamos el campo CONCEPTO
    if (formu.concepto === "0") {
        return {
            campo: "Concepto",
            error: "está sin seleccionar"
        };
    }

    //Validamos el campo COMENTARIO
    if (validator.isEmpty(formu.comentario)) {
        return {
            campo: "Comentario",
            error: "no puede estar vacío"
        };
    }
    if (!validator.isLength(formu.comentario, {min: 5, max: 30})) {
        return {
            campo: "Comentario",
            error: "debe tener entre 5 y 30 caracteres"
        };
    }
    if (!expresiones.comentario.test(formu.comentario)) {
        return {
            campo: "Comentario",
            error: "permitido letras, acentos y espacios"
        };
    }

    //Validamos el campo IMPORTE
    if (!validator.isNumeric(formu.importe)) {
        return {
            campo: "Importe",
            error: "incorrecto. Usar punto para decimal"
        };
    }

    // Si no ha salido por ningún sitio es que todo está correcto
    return {
        campo: "OK",
        error: "Todo correcto"
    };
}

export default ValidaApunte;
