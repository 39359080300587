import React from 'react';
import './App.css';
import Inicial from './componentes/Inicial';
import Login from './componentes/Login'
import Apunte from './componentes/Apunte';
import { useEffect, useState, useContext } from 'react';
import LlamaAPI from './helpers/LlamaAPI';
import ModoEjec from './contextos/ModoEjec';

function App() {
  const [usuario, setUsuario] = useState({});
  const [mostrar, setMostrar] = useState("Inicial");
  const [respuestaAPI, setRespuestaAPI] = useState({paso: "ninguno"});
  const { modoejecucion } = useContext(ModoEjec);

  useEffect( () => {
    //Revisar el LocalStorage para ver si están los datos almacenados
    let datos = localStorage.getItem('datos');
    console.log("Empieza APP2 - Modo ejecución: " + modoejecucion);
    console.log(datos);
    if (datos != null) {
      //Si hay datos guardados vemos si hay sesión abierta
      console.log("Tengo datos guardados en LocalStorage");
      let objeto = JSON.parse(datos);
      setUsuario(objeto);
      let url;
      if (modoejecucion === "produccion") {
        url = "https://eco.minave.es/api/usuarios/logged/" + objeto.coduser;
      } else {
        url = "http://localhost:3003/api/usuarios/logged/" + objeto.coduser;
      }
      LlamaAPI('logged', url, "GET", null, setRespuestaAPI);
    } else {
      console.log("No Tengo datos guardados");
      setMostrar("Login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect ( () => {
    if (respuestaAPI.paso === "logged") {
      if (respuestaAPI.respuesta.status === "correcto" && respuestaAPI.respuesta.session) {
        console.log("App2 - Hay sesión abierta. Timeout: " + respuestaAPI.respuesta.timeout + " - Quedan " + respuestaAPI.respuesta.queda + " sgs.");
        setMostrar("Apunte");
      } else {
        console.log("App2 - NO hay sesión abierta");
        let url;
        if (modoejecucion === "produccion") {
          url = "https://eco.minave.es/api/usuarios/login/";
        } else {
          url = "http://localhost:3003/api/usuarios/login/";
        }
        LlamaAPI('login', url, "POST", usuario, setRespuestaAPI);
      }
    }

    if (respuestaAPI.paso === "login") {    // Ha finalizado la petición de login
      console.log("Ya tengo respuesta de login");
      if (respuestaAPI.respuesta.status === "correcto") {
        console.log("App2: Abierta la sesión Coduser: " + respuestaAPI.respuesta.codusuario);
        setMostrar("Apunte");
      }
    }

    //console.log("Salimos de useEffect Momento - "+momento.estado);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[respuestaAPI]);

  return (
    <div className="App">
      {mostrar === "Inicial" ? <Inicial />
                             : mostrar === "Login" ? <Login setUsuario={setUsuario} setMostrar={setMostrar}/>
                                                   : <Apunte usuario={usuario} setMostrar={setMostrar} setUsuario={setUsuario} />}
    </div>
  );
}

export default App;