import { useState } from "react";

export const useForm = (objetoInicial = {}, setFormuCompleto) => {
    const [formu, setFormu] = useState(objetoInicial);

    const serializarFormu = (formu) => {
        const formData = new FormData(formu);
        const objCompleto = {};
        for (let [name, value, checked, type] of formData) {
            if (type === "checkbox") {
                if (checked) {
                    objCompleto[name] = "on";
                } else {
                    objCompleto[name] =  "off";
                }
            } else {
                objCompleto[name] = value;
            }
        }
        return objCompleto;
    }

    const enviado = (e) => {
        e.preventDefault();
        let obj = serializarFormu(e.target);
        console.log(obj);
        setFormu(obj);
        setFormuCompleto(true);
    }

    const cambiado = ({target}) => {
        const {name, value, checked, type} = target;
        //console.log("Name="+name+" Value="+value+" Checked="+checked+" Type="+type);
        if (type === "checkbox") {
            //console.log("apunto checked");
            if (checked) {
                setFormu({
                    ...formu,
                    [name]: "on"
                });
            } else {
                setFormu({
                    ...formu,
                    [name]: "off"
                });
            }

        } else {
            //console.log("apunto value");
            setFormu({
                ...formu,
                [name]: value
            });
        }
    }

    return {
        formu,
        enviado,
        cambiado
    }
}