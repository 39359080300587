import React, { useEffect, useState, useRef, useContext } from 'react';
import toast, {Toaster} from 'react-hot-toast';
import logo from '../imagenes/IdentificacionV2.png'
import { useForm } from '../hooks/useForm';
import ValidaLogin from '../helpers/ValidaLogin';
import LlamaAPI from '../helpers/LlamaAPI';
import ModoEjec from '../contextos/ModoEjec';
import './Login.css';

const Login = ({setUsuario, setMostrar}) => {
    const [formuCompleto, setFormuCompleto] = useState(false);   //lo cambia useForm cuando el formulario está completo
    const {formu, enviado, cambiado} = useForm({guarda: "off"}, setFormuCompleto);
    const [respuestaAPI, setRespuestaAPI] = useState({paso: "ninguno"}); 

    const refUsuario = useRef();                               //Referencias a los inputs
    const refClave = useRef();
    const refAlias = useRef();
    const refGuarda = useRef();
    const refAlerta = useRef();
    const refInfoAlerta = useRef();

    const { modoejecucion } = useContext(ModoEjec);

    //Se ejecuta al cargar el componente
    useEffect ( () => {
        refUsuario.current.select();
    }, []);
    
    //Esto se ejecuta cuando se pulsa Aceptar en el formulario
    useEffect( () => {
        if (formuCompleto) {
            //console.log("Se ha enviado el formulario");
            //console.log(formu);
            //Procesar el formulario. Si la propiedad "guarda" no existe en formu es porque no se ha marcado. 
            // En caso contrario "guarda=on"

            //Validar los campos del formulario
            const objResVal = ValidaLogin(formu);   //Devuelve un objeto con el resultado de la validación
            if (objResVal.campo !== "OK") {
                console.log("Tenemos error en el campo: " + objResVal.campo + " Descripción: " + objResVal.error);
                //Mostrar el error en un toast
                toast.error("El campo " + objResVal.campo + " " + objResVal.error, {
                    style: {
                        background: '#0146a5',    //#363636
                        color: '#fff',
                    }
                });
                //refInfoAlerta.current.innerHTML = "El campo " + objResVal.campo + " " + objResVal.error;
                //refAlerta.current.classList.remove('oculta');
                switch (objResVal.campo) {
                    case "Usuario":
                        refUsuario.current.select();
                        break;
                    case "Clave":
                        refClave.current.select();
                        break;
                    case "Alias":
                        refAlias.current.select();
                        break;
                    default:
                        break;
                }
            } else {
                //Validación correcta conectar con API para validar en el servidor y abrir sesión
                console.log(objResVal.error);
                let url;
                if (modoejecucion === "produccion") {
                    url = "https://eco.minave.es/api/usuarios/login/";
                } else {
                    url = "http://localhost:3003/api/usuarios/login/";
                }
                LlamaAPI('login', url, "POST", formu, setRespuestaAPI);
            }
            
            //Cambiamos a false para que se pueda pulsar otra vez enviar y reprocesar el formulario
            setFormuCompleto(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formuCompleto]);

    // Trata las posibles respuestas de LlamaAPI
    useEffect ( () => {
        if (respuestaAPI.paso === "login") {
            if (respuestaAPI.respuesta.status === "correcto") {
                let objeto = formu;
                objeto.coduser = respuestaAPI.respuesta.codusuario;
                setUsuario(objeto);
                //Ver si guardar los datos en el Localstorage
                if (formu.guarda === "on") {
                    localStorage.setItem('datos', JSON.stringify(objeto));
                }
                setMostrar("Apunte");
            }
            if (respuestaAPI.respuesta.status === "errorprog") {
                //Mostrar el error en un toast
                toast.error("Error: " + respuestaAPI.respuesta.descripcion, {
                    style: {
                        background: '#0146a5',    //#363636
                        color: '#fff',
                    }
                });
                //refInfoAlerta.current.innerHTML = "Error: " + respuestaAPI.respuesta.descripcion;
                //refAlerta.current.classList.remove('oculta');
                if (respuestaAPI.respuesta.descripcion.substring(0,5) === "Clave") {
                    refClave.current.select();
                } else {
                    refUsuario.current.select();
                }
            }
            if (respuestaAPI.respuesta.status === "errorsys") {
                //Mostrar el error en un toast
                toast.error("Error: " + respuestaAPI.respuesta.error, {
                    style: {
                        background: '#0146a5',    //#363636
                        color: '#fff',
                    }
                });
                //refInfoAlerta.current.innerHTML = "Error: " + respuestaAPI.respuesta.error;
                //refAlerta.current.classList.remove('oculta');
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [respuestaAPI]);

    const borrar = (e) => {
        refUsuario.current.value = "";
        refUsuario.current.select();    //Pone el foco en el campo usuario
        refClave.current.value = "";
        refAlias.current.value = "";
        refGuarda.current.checked = false;
        //refAlerta.current.classList.add('oculta');
    }

    return (
        <>
        <div className='container-fluid'>
            <header className='row justify-content-center cabecera'>
                <h2 className='col-12' id="identificacion">Identificación</h2>
                <h1 className='col-12' id="titulo"><small className='text-muted'>EconomiaCasera V2.0</small></h1>
                <figure className='col-12' id="logo">
                    <img className='img-fluid' width='100' src={logo} alt=''></img>
                </figure>
            </header>
{/*
            <section id="cajaalerta" className="alert alert-danger alert-dismissible fade show oculta" role="alert" ref={refAlerta}>
                <p>
                    <strong>Aviso: </strong>
                    <label id="texto" ref={refInfoAlerta}>
                    </label>
                </p>
            </section>
*/} 
            <section id="loginformu" className='row justify-content-center'>
                <form className='col-12'onSubmit={enviado}>

                    <div className="mb-3">
                        <label htmlFor="usuario" className="form-label">Usuario</label>
                        <input type="text" className="form-control" id="usuario" name="user" onChange={cambiado} ref={refUsuario} />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="clave" className="form-label">Clave</label>
                        <input type="password" className="form-control" id="clave" name="clave" onChange={cambiado} ref={refClave} />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="alias" className="form-label">Alias</label>
                        <input type="text" className="form-control" id="alias" name="alias" onChange={cambiado} ref={refAlias} />
                    </div>

                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="guarda" name="guarda" onChange={cambiado} ref={refGuarda} aria-checked="false"/>
                        <label className="form-check-label" htmlFor="guarda">Recordar datos de conexión</label>
                    </div>
                    
                    <div id="botones">
                        <button type="submit" className="btn btn-primary aceptar" name="aceptar">Aceptar</button>
                        <button type="button" className="btn btn-primary" name="Borrar" onClick={borrar} >Borrar</button>
                    </div>
                </form>
            </section>

            <footer id="footLogin">
                <p>Usuario={formu.user} Clave={formu.clave} Alias={formu.alias} Guarda={formu.guarda}</p>
            </footer>
        </div>
        <Toaster position='top-center' />
        </>
    )
}

export default Login;