//Valida el formulario de Identificación
//  devuelve un objeto con el resultado de la validación

import validator from 'validator';

const ValidaLogin = ({user, clave, alias}) => {

    const expresiones = {
        usuario: /^[a-zA-Z0-9\_\-]{4,10}$/,        //eslint-disable-line
        password: /^.{6,16}$/,                     // 6 a 16 digitos.
        alias: /^[a-zA-ZÀ-ÿ\s]{4,20}$/,            // Letras y espacios, pueden llevar acentos.
    }

    //Validamos el campo USER
    if (validator.isEmpty(user)) {
        return {
            campo: "Usuario",
            error: "no puede estar vacío"
        };
    }
    if (!validator.isLength(user, {min: 4, max: 10})) {
        return {
            campo: "Usuario",
            error: "debe tener entre 4 y 10 caracteres"
        };
    }

    if (!expresiones.usuario.test(user)) {
        return {
            campo: "Usuario",
            error: "sólo se permiten letras, números, guión medio y bajo"
        };
    }

    //Validamos el campo CLAVE
    if (validator.isEmpty(clave)) {
        return {
            campo: "Clave",
            error: "no puede estar vacío"
        };
    }
    if (!validator.isLength(clave, {min: 6, max: 16})) {
        return {
            campo: "Clave",
            error: "Debe tener entre 6 y 16 caracteres"
        };
    }

    if (!expresiones.password.test(clave)) {
        return {
            campo: "Clave",
            error: "Permitido cualquier caracter entre 6 y 16 posiciones"
        };
    }

    //Validamos el campo ALIAS
    if (validator.isEmpty(alias)) {
        return {
            campo: "Alias",
            error: "no puede estar vacío"
        };
    }
    if (!validator.isLength(alias, {min: 4, max: 20})) {
        return {
            campo: "Alias",
            error: "Debe tener entre 4 y 20 caracteres"
        };
    }

    if (!expresiones.alias.test(alias)) {
        return {
            campo: "cAlias",
            error: "Permitido letras con acentos y espacios"
        };
    }

    // Si no ha salido por ningún sitio es que todo está correcto
    return {
        campo: "OK",
        error: "Todo correcto"
    };
}

export default ValidaLogin;
