import { createContext, useState } from "react";

const ModoEjec = createContext();

const ModoEjecProvider = ({children}) => {

    const [modoejecucion, setModoejecucion] = useState("produccion");      // produccion / desarrollo
 
    const data = {modoejecucion};

    return (
        <ModoEjec.Provider value={data}>
            {children}
        </ModoEjec.Provider>
    )
};

export {ModoEjecProvider};
export default ModoEjec;