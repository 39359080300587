import React from 'react';
import logo from '../imagenes/IdentificacionV2.png'

const Inicial = () => {

    return (
        <>
        <div className='container-fluid'>
            <header className='row justify-content-center cabecera'>
                <h2 className='col-12' id="identificacion">EconomiaCasera V2.0</h2>
                <h1 className='col-12' id="titulo"><small className='text-muted'>Cargando...</small></h1>
                <figure className='col-12' id="logo">
                    <img className='img-fluid' width='100' src={logo} alt=''></img>
                </figure>
            </header>
        </div>
        </>
    );
}

export default Inicial;